<template>
    <div>
        <b-modal no-close-on-backdrop scrollable title="Import Report" class="modal-dark" size="lg" id="importFileModal" hide-header-close>
            <b-row>
                <b-col sm="12">
                    <!-- Conversion Types -->
                    <div>Types of Conversion</div>
                    <b-form-group style="padding-bottom: 5px;">
                        <b-form-radio v-model="convertOption" :value="0">
                            Convert as single Category with multiple items
                            <span style="color: #d00">(Import will overwrite existing info inside the chosen category)</span>
                        </b-form-radio>
                        <b-form-radio v-model="convertOption" :value="1">
                            Convert as whole Report
                            <span style="color: #888;">(Fields will need to match exact name to import)</span>
                            <span style="color: #d00"> (Import will overwrite the existing report)</span>
                        </b-form-radio>
                    </b-form-group>
                    <!-- CSV Import -->
                    <input v-if="convertOption !== null" type="file" ref='csvfile' @change="convertFile()" accept=".csv,application/vnd.ms-excel"/><br>
                    <!-- Select Single Category Import -->
                    <div v-if="convertOption === 0 && items.length > 0" style="padding-top: 20px;">
                        <span>Select Category to import to</span>
                        <b-form-group>
                            <b-form-radio v-for="(category, index) in categories" v-model="selectedCategoryIndex" :key="category._id" :value="index" @input="createFieldSlots">{{category.name}}</b-form-radio>
                        </b-form-group>
                        <!-- Map Fields -->
                        <div v-if="selectedCategoryIndex !== null" class="d-flex justify-content-start">
                            <!-- Fields Found -->
                            <div style="padding-right: 35px;">
                                <span style="font-size: 18px;"><u>Imported Fields</u></span>
                                <div v-if="items.length > 0">
                                    <div v-for="field in importFieldNames">
                                        <b-badge style="cursor: pointer; font-size: 13px; color: black; height: 20px; margin-bottom: 2px;"
                                                 draggable="true"
                                                 @dragstart="dragField($event, field)"
                                                 @dragover.prevent
                                                 variant="primary">
                                            {{field.name}}
                                        </b-badge> <span style="color: #888;">({{field.example}})</span>
                                    </div>
                                </div>
                                <div v-else> No fields found </div>
                            </div>
                            <!-- Available Fields -->
                            <div class="flex-grow-1">
                                <span style="font-size: 18px; width: 100%"><u>Available Fields</u></span>
                                <div v-if="categories[selectedCategoryIndex].item.fields.length > 0">
                                    <div v-for="field in selectedCategorySorted" class="d-flex">
                                        <div>{{field.name}} -> </div>
                                        <div style="padding-left: 3px;">
                                            <b-badge style="cursor: pointer; font-size: 13px; color: black; height: 20px; margin-bottom: 2px;"
                                                     :style="availableFieldSlots[field._id].name === '' ? 'width: 40px;' : ''"
                                                     @drop="dropField($event, field._id)"
                                                     @dragover.prevent
                                                     variant="primary">
                                                &nbsp;{{availableFieldSlots[field._id].name }}&nbsp;
                                                <fa-icon v-if="availableFieldSlots[field._id].name !== ''" :icon="['fas', 'remove']" @click="removeField(field)"/>
                                            </b-badge> <span v-if="availableFieldSlots[field._id].name !== ''" style="color: #888;">({{availableFieldSlots[field._id].example !== undefined ? availableFieldSlots[field._id].example : 'No Example Found'}})</span>
                                        </div>
                                    </div>
                                </div>
                                <div v-else> No fields found </div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button v-if="importLoading === false" size="sm" variant="secondary" @click="closeImportModal()">
                    Cancel
                </b-button>
                <b-button size="sm" variant="primary" :disabled="!checkImportReady || importLoading === true" @click="importCSV()">
                    <span v-if="importLoading === false">Import</span>
                    <fa-icon v-else :icon="['fas', 'spinner']" spin style="padding: 0 14px; cursor: default;"/>
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import Papa from 'papaparse';
import draggable from "vuedraggable";
import Vue from 'vue';
import {v4 as uuidv4} from 'uuid';
export default {
    name: "csvImport",
    props: {
        categories: {
            type: Array,
            default: () => []
        },
        importSingleCategory: {
            type: Function
        },
        importWholeReport: {
            type: Function
        },
        importLoading: {
            type: Boolean,
            default: false
        }
    },
    components: {
        draggable
    },
    data() {
        return {
            items: [],
            convertOption: null,
            selectedCategoryIndex: null,
            selectedCategorySorted: null,
            importFieldNames: [],
            availableFieldSlots: {}
        }
    },
    methods: {
        async importCSV() {
            if (this.convertOption === 0) {
                await this.importSingleCategory(this.availableFieldSlots, this.items, this.categories[this.selectedCategoryIndex]._id, this.selectedCategoryIndex);
            } else if (this.convertOption === 1) {
                await this.importWholeReport(this.items[0])
            }
            this.clearImportFields();
        },
        convertFile() {
            let file = this.$refs.csvfile.files[0];
            if (file !== undefined) {
                Papa.parse(file, {
                    header: true,
                    complete: results => {
                        this.items = results.data;
                        if (this.items.length > 0) {
                            this.importFieldNames = Object.keys(this.items[0]).sort((a, b) => {
                                if (a > b)
                                    return 1;
                                if (a < b)
                                    return -1;
                                return 0;
                            }).map(field => {
                                {
                                    return {
                                        name: field,
                                        _id: uuidv4(),
                                        example: this.items.find(item => item[field] !== '')[field]
                                    };
                                }
                            });
                        }
                    }
                });
            }
        },
        sortAlphabetically(items) {
            return items.sort((a,b) => {
                if (a.name > b.name)
                    return 1;
                if (a.name < b.name)
                    return -1;
                return 0;
            });
        },
        createFieldSlots() {
            this.categories[this.selectedCategoryIndex].item.fields.forEach(field => {
                Vue.set(this.availableFieldSlots, field._id, {name: '', _id: null, example: null});
            });
            this.selectedCategorySorted = this.sortAlphabetically(this.categories[this.selectedCategoryIndex].item.fields);
        },
        dragField(event, field) {
            event.dataTransfer.setData('text', JSON.stringify(field));
        },
        dropField(event, fieldId) {
            let newField = JSON.parse(event.dataTransfer.getData('text'));
            let oldField = this.availableFieldSlots[fieldId];
            if (oldField.name !== '') {
                this.importFieldNames.push(oldField);
                this.importFieldNames = this.sortAlphabetically(this.importFieldNames);
            }
            this.availableFieldSlots[fieldId] = newField;
            let index = this.importFieldNames.findIndex(field => {
                return field._id === newField._id;
            });
            this.importFieldNames.splice(index, 1);
        },
        removeField(field) {
            let oldField = this.availableFieldSlots[field._id];
            this.importFieldNames.push(oldField);
            this.importFieldNames = this.sortAlphabetically(this.importFieldNames);
            Vue.set(this.availableFieldSlots, field._id, {name: '', _id: null, example: null});
        },
        clearImportFields() {
            this.convertOption = null;
            this.selectedCategoryIndex = null;
            this.items = [];
            this.selectedCategorySorted = null;
            this.importFieldNames = null;
            this.availableFieldSlots = {};
        },
        closeImportModal() {
            this.$bvModal.hide('importFileModal');
            this.clearImportFields();
        }
    },
    computed: {
        checkImportReady() {
            //make sure an option is selected, a CSV file has been imported and converted, and a category to place into has been selected
            return (this.convertOption === 0 && this.items.length > 0 && this.selectedCategoryIndex !== null) ||
                    (this.convertOption === 1 && this.items.length > 0);
        }
    },
    watch: {
        importLoading() {
            if (this.importLoading === false) {
                this.closeImportModal();
            }
        },
        convertOption() {
            if (this.$refs.csvfile) {
                this.$refs.csvfile.value = null;
            }
        }
    }
}
</script>

<style scoped>

</style>