<template>
    <div class="d-flex flex-wrap" style="padding: 5px">
        <b-col v-for="(field, fieldIndex) in item.fields" :key="field._id" :sm="field.size" style="padding: 5px" @click="selectField(field)">
            <div style="padding-left: 3px" v-if="field.type !== 'address' && field.type !== 'inmate' && field.type !== 'officer' && field.type !== 'spacer'">
                <b>{{field.name}}</b>
                <span v-if="field.type === 'richTextBox' && field.textLength > 0" style="color: #aaa;"> {{`(${getCurrentCharCount(field._id)} / ${field.textLength})`}}</span>
            </div>
            <b-input-group v-if="field.type === 'textBox'" :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <b-form-input class="input-height fieldBackgroundColor"
                              :disabled="!editable || !field.editable"
                              v-model="localItem[field._id]"
                              :id="`field-${field._id}${index}`"
                              :placeholder="!field.editable ? localItem[field._id] : field.description"
                              :maxlength="field.size/3*30"/>
            </b-input-group>
            <b-input-group v-if="field.type === 'richTextBox'" :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <b-form-textarea class="form-control rounded-0 input-height fieldBackgroundColor" :id="`field-${field._id}${index}`"
                          :placeholder="field.description"
                          rows="3" size="lg" max-rows="6"
                          :maxlength="field.textLength > 0 ? field.textLength : ''"
                          type="text"
                          :disabled="!editable"
                          v-model="localItem[field._id]"/>
            </b-input-group>
            <b-input-group v-if="field.type === 'checkbox'" :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <b-input-group-text style="width: 100%">
                    <b-checkbox :disabled="!editable" v-model="localItem[field._id]" :id="`field-${field._id}${index}`"/> {{ field.name }}
                </b-input-group-text>
            </b-input-group>
            <b-input-group v-if="field.type === 'dropdownBox'" style="width: inherit;" :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <b-form-select :disabled="!editable"
                               v-model="localItem[field._id]"
                               :options="[{value: '', text: ''}].concat(field.values)"
                               style="cursor:pointer;"
                               class="input-height fieldBackgroundColor"
                               :id="`field-${field._id}${index}`"/>
            </b-input-group>
            <b-input-group v-if="field.type === 'searchableDropdownBox'" style="width: inherit;" :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <form-select-search :disabled="!editable" disabledBackgrondColor="#333333" BackgroundColor="#1a1a1a"
                                    v-model="localItem[field._id]" :userValue="false" :id="`field-${field._id}${index}`"
                                    :options="field.values"
                                    class="input-height fieldBackgroundColor"
                                    style="cursor:pointer; width: 100%"/>

            </b-input-group>
            <b-input-group v-if="field.type === 'date'" :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <date-picker v-model="localItem[field._id]"
                             :readonly="!editable"
                             :style="!editable === true ? 'background-color: #333333' : ''"
                             :config="{
                                 format: field.format.date,
                                 useCurrent: true,
                                 widgetPositioning: {
                                     horizontal: checkFieldPosition(fieldIndex),
                                     vertical: 'auto'
                                 }
                             }"
                             :placeholder="field.description === '' ? field.format.date : field.description"
                             class="input-height fieldBackgroundColor"
                             :id="`field-${field._id}${index}`"/>
            </b-input-group>
            <b-input-group v-if="field.type === 'dateTime'" :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <date-picker v-model="localItem[field._id]"
                             :readonly="!editable"
                             :style="!editable === true ? 'background-color: #333333' : ''"
                             :config="{
                                       format:field.format.military !== true?field.format.date+' '+field.format.time+' a': field.format.date+' '+field.format.time,
                                       useCurrent: true,
                                       sideBySide: false,
                                       widgetPositioning: {
                                           horizontal: checkFieldPosition(fieldIndex),
                                           vertical: 'auto'
                                       }
                                      }"
                             :placeholder="field.description !== '' ? field.description : field.format.military === true ? field.format.date+' '+field.format.time : field.format.date+' '+field.format.time+' a'"
                             class="input-height fieldBackgroundColor"
                             :id="`field-${field._id}${index}`"/>
            </b-input-group>
            <b-input-group v-if="field.type === 'time'" :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <date-picker v-model="localItem[field._id]"
                             :readonly="!editable"
                             :style="!editable === true ? 'background-color: #333333' : ''"
                             :config="{
                                 format: field.format.military !== true?field.format.time+' a': field.format.time,
                                 useCurrent: true,
                                 widgetPositioning: {
                                     horizontal: checkFieldPosition(fieldIndex),
                                     vertical: 'auto'
                                 }
                             }"
                             :placeholder="field.description !== '' ? field.description : field.format.military === true ? field.format.time : field.format.time+' a'"
                             class="input-height fieldBackgroundColor"
                             :id="`field-${field._id}${index}`"/>
            </b-input-group>
            <b-input-group v-if="field.type === 'number'" :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <b-form-input class="input-height fieldBackgroundColor"
                              :disabled="!editable"
                              v-model="localItem[field._id]"
                              :id="`field-${field._id}${index}`"
                              type="number"
                              :placeholder="field.description"
                              :maxlength="field.size/3*30"/>
            </b-input-group>
            <b-input-group v-if="field.type === 'phoneNumber'" :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <masked-input :id="`field-${field._id}${index}`"
                              class="form-control input-height fieldBackgroundColor"
                              :disabled="!editable"
                              :placeholder="field.description"
                              v-model="localItem[field._id]"
                              :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                              placeholderChar="_"/>
            </b-input-group>
            <b-card v-if="field.type === 'address'"
                    bg-variant="dark"
                    class="address-b-card bigFieldBackgroundColor"
                    :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <div slot="header">
                    <span><b>{{ field.name }}</b></span>
                </div>
                <div style="margin: -5px">
                    <b-row>
                        <b-col sm="7">
                            <div style="padding-left: 3px;"><b>Address</b></div>
                            <b-input-group style="margin-bottom: 10px" :id="`field-${field._id}${index}-address`">
                                <b-form-input :id="'autocomplete-' + index + '-' + field._id"
                                              placeholder="Enter Address"
                                              type="text"
                                              style="flex: 3;"
                                              @input="selectedAddress = field._id; addressInput = localItem[field._id].address"
                                              v-model="localItem[field._id].address"
                                              types=""
                                              :formatter="formatAddress"
                                              :disabled="!editable"
                                              autocomplete="on"
                                              class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </b-col>
                        <b-col sm="5">
                            <div style="padding-left: 3px;"><b>Apartment/Unit</b></div>
                            <b-input-group>
                                <b-form-input @input="selectedAddress = field._id"
                                              v-model="localItem[field._id].apartment"
                                              :disabled="!editable"
                                              style="flex: 2"
                                              class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <div style="padding-left: 3px;"><b>City</b></div>
                            <b-input-group>
                                <b-form-input @input="selectedAddress = field._id"
                                              v-model="localItem[field._id].city"
                                              :disabled="!editable"
                                              class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <div style="padding-left: 3px;"><b>State</b></div>
                            <b-input-group>
                                <b-form-select @input="selectedAddress = field._id"
                                               v-model="localItem[field._id].state"
                                               :disabled="!editable"
                                               :options="states"
                                               class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </b-col>
                        <b-col>
                            <div style="padding-left: 3px;"><b>Zip</b></div>
                            <b-input-group>
                                <b-form-input @input="selectedAddress = field._id"
                                              v-model.number="localItem[field._id].zip"
                                              :disabled="!editable"
                                              type="number"
                                              class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </b-col>
                    </b-row>
                </div>
            </b-card>
            <b-card v-if="field.type === 'inmate'"
                    @click=selectField(field._id)
                    bg-variant="dark"
                    class="address-b-card bigFieldBackgroundColor"
                    :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <div slot="header" class="d-flex justify-content-between">
                    <div>
                        <span><b>{{ field.name }}</b>&nbsp;</span>
                        <fa-icon :icon="['fas', 'circle-question']" @mouseenter="toggleInfoBox(field._id)" @mouseleave="toggleInfoBox(field._id)"/>
                        <h5 class="infoBox" :id="'info'+field._id" style="display: none">
                            <span class="infoTitle">Search Field</span>
                            <span class="infoText">{{howToSearchInfo}}</span><br><br>
                            <span class="infoTitle">Highlighted Fields</span>
                            <span class="infoText">{{autofilledFields}}</span><br>
                        </h5>
                    </div>
                    <fa-icon v-if="searchingForResults === false" :icon="['fas', 'magnifying-glass']" @click="searchInmates(field._id)"
                             style="cursor: pointer; margin-top: 3px;" title="Search"/>
                    <div v-else>
                        <fa-icon :icon="['fas', 'spinner']" spin/>
                    </div>
                </div>
                <div style="margin: -5px">
                    <div style="display:flex;">
                        <div style="flex: 3; padding-right: 30px;">
                            <div style="padding-left: 3px;"><b>Last Name</b></div>
                            <b-input-group>
                                <b-form-input v-model="localItem[field._id].lastName" :disabled="!editable" class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </div>
                        <div style="flex: 3; padding-right: 30px;">
                            <div style="padding-left: 3px;"><b>First Name</b></div>
                            <b-input-group style="margin-bottom: 10px" :id="`field-${field._id}${index}-inmate`">
                                <b-form-input v-model="localItem[field._id].firstName" :disabled="!editable" class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </div>
                        <div style="flex: 2;">
                            <div style="padding-left: 3px;"><b>Middle Name</b></div>
                            <b-input-group>
                                <b-form-input v-model="localItem[field._id].middleName" :disabled="!editable" class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </div>
                    </div>
                    <div style="display: flex; gap: 30px">
                        <div style="flex: 1">
                            <div style="padding-left: 3px;"><b>MID</b></div>
                            <b-input-group>
                                <b-form-input v-model="localItem[field._id].mid" :disabled="!editable" class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </div>
                        <div style="flex: 1">
                            <div style="padding-left: 3px;"><b>DOB</b></div>
                            <b-input-group>
                                <date-picker v-model="localItem[field._id].dob" :disabled="!editable"
                                             :config="dateOptions" placeholder="MM/DD/YYYY" class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </div>
                        <div style="flex: 1">
                            <div style="padding-left: 3px;"><b>SSN</b></div>
                            <b-input-group>
                                <masked-input v-model="localItem[field._id].ssn"
                                              :disabled="!editable"
                                              type="text"
                                              name="ssn"
                                              class="input-height form-control fieldBackgroundColor"
                                              :mask="[/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                                              :guide="true"
                                              placeholderChar="_"/>
                            </b-input-group>
                        </div>
                    </div>
                </div>
            </b-card>
            <b-card v-if="field.type === 'officer'"
                    @click=selectField(field._id)
                    bg-variant="dark"
                    class="address-b-card bigFieldBackgroundColor"
                    :class="inputBorderColor(field.required, localItem[field._id], field._id)">
                <div slot="header" class="d-flex justify-content-between">
                    <div>
                        <span><b>{{ field.name }}</b>&nbsp;</span>
                        <fa-icon :icon="['fas', 'circle-question']" @mouseenter="toggleInfoBox(field._id)" @mouseleave="toggleInfoBox(field._id)"/>
                        <h5 class="infoBox" :id="'info'+field._id" style="display: none">
                            <span class="infoTitle">Search Field</span>
                            <span class="infoText">{{howToSearchInfo}}</span><br><br>
                            <span class="infoTitle">Highlighted Fields</span>
                            <span class="infoText">{{autofilledFields}}</span><br><br>
                        </h5>
                    </div>
                    <fa-icon v-if="searchingForResults === false" :icon="['fas', 'magnifying-glass']" @click="searchOfficers(field._id)"
                             style="cursor: pointer; margin-top: 3px;" title="Search"/>
                    <div v-else>
                        <fa-icon :icon="['fas', 'spinner']" spin/>
                    </div>
                </div>
                <div style="margin: -5px">
                    <div style="display:flex;">
                        <div style="flex: 3; padding-right: 30px;">
                            <div style="padding-left: 3px;"><b>Last Name</b></div>
                            <b-input-group>
                                <b-form-input v-model="localItem[field._id].lastName" :disabled="!editable" class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </div>
                        <div style="flex: 3; padding-right: 30px;">
                            <div style="padding-left: 3px;"><b>First Name</b></div>
                            <b-input-group style="margin-bottom: 10px" :id="`field-${field._id}${index}-officer`">
                                <b-form-input v-model="localItem[field._id].firstName" :disabled="!editable" class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </div>
                        <div style="flex: 2;">
                            <div style="padding-left: 3px;"><b>Star #</b></div>
                            <b-input-group>
                                <b-form-input v-model="localItem[field._id].starNumber" :disabled="!editable" class="input-height fieldBackgroundColor"/>
                            </b-input-group>
                        </div>
                    </div>
                </div>
            </b-card>
        </b-col>
    </div>
</template>

<script>

import Report from "@/services/report";
import datePicker from "vue-bootstrap-datetimepicker";
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import MaskedInput from 'vue-text-mask';
import PubSub from "pubsub-js";
import FormSelectSearch from "@/views/expansion_modules/risc/formSelectSearch";

export default {
    name: "Field",
    components: {
        FormSelectSearch,
        datePicker,
        MaskedInput,
    },
    props: {
        editable: Boolean,
        item: Object,
        index: Number,
        reportItem: Object,
        localItem: Object,
        categoryId: String,
        categoryName: String,
        singleItem: Boolean,
        highlightFields: Array,
        google: HTMLScriptElement
    },
    data() {
        return {
            howToSearchInfo: 'Enter the information below that you have, or want to search by. ' +
                'Then click on the search icon to the right. ' +
                'Your results will appear inside the right side bar. ' +
                'Note: The text inside this field does not show up on the pdf printout. ',
            autofilledFields: 'All fields highlighted in green when you select this search box ' +
                "will attempt to be autofilled with the selected person's information. ",
            searchingForResults: false,
            addressInput: '',
            dateOptions: {
                format: 'MM/DD/YYYY',
                useCurrent: true,
            },
            states: [
                'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
                'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
                'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
                'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
                'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
            ],
            pubsubs: []
        }
    },
    created() {
        this.pubsubs.push(PubSub.subscribe('resultSelected'+this.reportItem._id, (msg, data) => {
            this.autoFill(data.result, data.fieldId);
        }));
        this.pubsubs.push(PubSub.subscribe('focusField'+this.index, (msg, data) => {
            if (this.toggle === true) {
                document.getElementById(data.itemId).scrollIntoView();
            }
            if (data.fieldId && document.getElementById("field-" + data.fieldId + this.index + '-address') !== null
                && document.getElementById("field-" + data.fieldId + this.index + '-address').children[0]) {
                document.getElementById("field-" + data.fieldId + this.index + '-address').children[0].focus();
            } else if (data.fieldId && document.getElementById("field-" + data.fieldId + this.index + '-inmate') !== null
                && document.getElementById("field-" + data.fieldId + this.index + '-inmate').children[0]) {
                document.getElementById("field-" + data.fieldId + this.index + '-inmate').children[0].focus();
            } else if (data.fieldId && document.getElementById("field-" + data.fieldId + this.index + '-officer') !== null
                && document.getElementById("field-" + data.fieldId + this.index + '-officer').children[0]) {
                document.getElementById("field-" + data.fieldId + this.index + '-officer').children[0].focus();
            } else if (data.fieldId && document.getElementById("field-" + data.fieldId + this.index) !== null) {
                document.getElementById("field-" + data.fieldId + this.index).focus();
            } else if (data.fieldId) {
                console.error("Error: Could not open field 'field-" + data.fieldId + this.index + "'");
            }
        }));
        this.$emit('saveItem', {item: this.localItem, index: this.index, savable: this.isSavable});
    },
    beforeDestroy() {
        this.pubsubs.forEach(pubsub => {
            PubSub.unsubscribe(pubsub);
        });
    },
    methods: {
        getCurrentCharCount(fieldId) {
            if (this.localItem[fieldId] !== undefined) {
                return this.localItem[fieldId].length;
            }
            return 0;
        },
        checkFieldPosition(fieldIndex) {
            let sizeTotal = 0;
            for (let i = 0; i < fieldIndex; i++) {
                sizeTotal += this.item.fields[i].size;
            }
            if (sizeTotal % 12 === 6 && this.item.fields[fieldIndex].size === 3) {  //this one catches 6/3
                return 'right';
            } else if (sizeTotal % 12 === 0 || ((sizeTotal % 12) + this.item.fields[fieldIndex].size !== 12) || ((sizeTotal % 12) < this.item.fields[fieldIndex].size)) {
                return 'auto';
            } else {  //this should return for 6/6 and 9/3
                return 'right';
            }
        },
        makeChargeHistory(arr) {
            let result = "";
            for (let i = 0; i < arr.length; i++) {
                result += (i !== 0 ? "\n\n" : "") + (i + 1) + ") ";
                result += arr[i].statuteNumber + " (" + arr[i].offenseDateTime + ")\n";
                result += "Desc: " + arr[i].statuteDescription;
            }
            return result;
        },
        makeAliasList(array) {
            let result = "";
            for (let i = 0; i < array.length; i++) {
                result += i > 0 ? "\n" : "";
                result += array[i].nameType === 'Master' ? '(Main Alias) ' : '(Alias) ' + i + ": ";
                result += (array[i].firstName || '') + " " + (array[i].middleName || '') + " " + (array[i].lastName || '');
            }
            return result;
        },
        selectField(field) {
            this.$emit('selectField', field)
        },
        toggleInfoBox(id) {
            let x = document.getElementById('info'+id);
            if (x.style.display === "none") {
                x.style.display = "block";
            } else {
                x.style.display = "none";
            }
        },
        inputBorderColor(isRequired, value, id) {
            if (isRequired && !this.filledOut(value)) {
                return 'border border-danger rounded';
            } else {
                let count = 0;
                try {
                    for (const field of this.highlightFields) {
                        if (field.id === id) {
                            return 'border border-success rounded';
                        }
                        count++;
                        if (count === this.highlightFields.length) {
                            return 'border border-dark rounded';
                        }
                    }
                } catch (e) {
                    return 'border border-dark rounded';
                }
            }
        },
        filledOut(i) {
            if (typeof i === 'object' && i !== null) {
                for (let index = 0; index < Object.keys(i).length; index++) {
                    if (this.filledOut(i[Object.keys(i)[index]])) {
                        return true;
                    }
                }
                return false;
            } else {
                return i !== null && i !== undefined && i !== '' && i !== 0 && i !== false;
            }
        },
        searchInmates(id) {
            if (this.filledOut(this.localItem[id])) {
                this.searchingForResults = true;
                Report.getInmates(this.localItem[id]).then(response => {
                    setTimeout(() => {
                        //the timeout is here to simulate the waiting time for the search
                        PubSub.publish('displaySearchResults', {results: response.data, fieldId: id, itemId: this.reportItem._id});
                        this.searchingForResults = false;
                    }, 100);
                });
            }
        },
        searchOfficers(id) {
            if (this.filledOut(this.localItem[id])) {
                this.searchingForResults = true;
                Report.getOfficers(this.localItem[id]).then(response => {
                    //the timeout is here to simulate the waiting time for the search
                    setTimeout(() => {
                        PubSub.publish('displaySearchResults', {results: response.data, fieldId: id, itemId: this.reportItem._id});
                        this.searchingForResults = false;
                    }, 100);
                });
            }
        },
        formatAddress(e) {
            //this limits the max characters inside an address
            return String(e).substring(0, 100);
        },
        async getAddressData(autocomplete) {
            this.$forceUpdate();
            const place = await autocomplete.getPlace();
            const ac = place.address_components;
            let streetNumber = '';
            let route = '';
            for (let i = 0; i < ac.length; i++) {
                let addressType = ac[i].types[0];
                if (addressType === 'street_number') {
                    streetNumber = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'route') {
                    route = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'intersection') {
                    route = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'locality') {
                    this.localItem[this.selectedAddress].city = ac[i].long_name.toUpperCase();
                }
                if (addressType === 'administrative_area_level_1') {
                    this.localItem[this.selectedAddress].state = ac[i].short_name.toUpperCase();
                }
                if (addressType === 'postal_code') {
                    this.localItem[this.selectedAddress].zip = parseInt(ac[i].long_name);
                }
            }
            this.localItem[this.selectedAddress].address = streetNumber + ' ' + route;
        },
        autoFill(officer, id) {
            let index = this.item.fields.findIndex(field => {
                return field._id === id;
            })
            if (this.item.fields[index].values[0] !== '') {
                this.item.fields[index].values.forEach(value => {
                    let index2 = this.item.fields.findIndex(field2 => {
                        return field2._id === value.id;
                    })
                    if (index2 !== -1) {
                        if (value.link === 'currentLocation.bed') {
                            this.localItem[value.id] = officer.currentLocation.bed;
                        } else if (value.link === 'currentLocation.cell') {
                            this.localItem[value.id] = officer.currentLocation.cell;
                        } else if (value.link === 'currentLocation.floor') {
                            this.localItem[value.id] = officer.currentLocation.floor;
                        } else if (value.link === 'currentLocation.pod') {
                            this.localItem[value.id] = officer.currentLocation.pod;
                        } else if (value.link === 'secondaryAddress.streetNumber') {
                            this.localItem[value.id] = officer.secondaryAddress.streetNumber;
                        } else if (value.link === 'secondaryAddress.streetName') {
                            this.localItem[value.id] = officer.secondaryAddress.streetName;
                        } else if (value.link === 'secondaryAddress.apartment') {
                            this.localItem[value.id] = officer.secondaryAddress.apartment;
                        } else if (value.link === 'secondaryAddress.city') {
                            this.localItem[value.id] = officer.secondaryAddress.city;
                        } else if (value.link === 'secondaryAddress.state') {
                            this.localItem[value.id] = officer.secondaryAddress.state;
                        } else if (value.link === 'secondaryAddress.zip') {
                            this.localItem[value.id] = officer.secondaryAddress.zip;
                        } else if (value.link === 'secondaryAddress.country') {
                            this.localItem[value.id] = officer.secondaryAddress.country;
                        } else if (value.link === 'nameAndBadge') {
                            this.localItem[value.id] = officer.lastName + ', ' + officer.firstName + ' ' + officer.starNumber;
                        } else if (value.link === 'heightBoth') {
                            this.localItem[value.id] = officer.heightFeet + "'" + officer.heightInches + '"';
                        } else if (value.link === 'age') {
                            this.localItem[value.id] = Math.floor((Date.now() - new Date(officer.dateOfBirth).getTime()) / 31556926000);
                        } else if (value.link === 'dateOfBirth') {
                            this.localItem[value.id] = new Date(officer.dateOfBirth).toLocaleDateString();
                        } else if (value.link === 'chargeHistory') {
                            this.localItem[value.id] = this.makeChargeHistory(officer.chargeHistory);
                        } else if (value.link === 'aliasAKA') {
                            this.localItem[value.id] = this.makeAliasList(officer.aliasAKA);
                        } else {
                            this.localItem[value.id] = officer[value.link];
                        }
                    }
                })
            }
        }
    },
    watch: {
        toggle() {
            PubSub.publish('toggleItemIcon', this.reportItem._id);
        },
        localItem: {
            handler() {
                if (this.pageLoad === true) {
                    if (this.localItem !== null && this.localItem !== undefined && this.localItem !== {}) {
                        this.$emit('saveItem', {item: this.localItem, index: this.index, savable: this.isSavable});
                        PubSub.publish('autoSaveRequest', {timer: 15000});
                    }
                } else {
                    //this will execute on page load unless we prevent it with this
                    this.pageLoad = true;
                    this.$emit('saveItem', {item: this.localItem, index: this.index, savable: this.isSavable});
                }
            },
            deep: true
        },
        addressInput() {
            const autocomplete = new google.maps.places.Autocomplete(
                document.getElementById('autocomplete-' + this.index + '-' + this.selectedAddress), {types: ['geocode']}
            );
            autocomplete.setFields(['address_components']);
            autocomplete.addListener('place_changed', () => {
                this.getAddressData(autocomplete);
            });
        },
    },
    computed: {
        savable() {
            let array = [];
            let errorsArr = [];
            for (let i = 0; i < this.item.fields.length; i++) {
                let status = (this.item.fields[i].required && this.filledOut(this.localItem[this.item.fields[i]._id]))
                    || !this.item.fields[i].required;
                array.push(status);
                if (!status) {
                    errorsArr.push(this.item.fields[i]);
                }
            }
            PubSub.publish('requiredFormItems', {
                categoryName: this.categoryName,
                categoryId: this.categoryId,
                itemId: this.reportItem._id,
                errorsArr: errorsArr,
                itemName: this.item.name,
                itemIndex: this.index + 1,
                singleItem: this.singleItem
            });
            return array;
        },
        isSavable() {
            let result = true;
            for (let i = 0; i < this.savable.length; i++) {
                if (this.savable[i] === false) {
                    result = false;
                    break;
                }
            }
            PubSub.publish('itemIsSavable', {
                itemId: this.reportItem._id,
                isSavable: result
            })
            return result;
        }
    }
}
</script>

<style scoped>
.card-header {
    background-color: #2a2a2a;
    padding: 12px 20px !important;
}
.card-header:hover {
    background-color: #141414;
}
.address-b-card .card-header:hover {
    background-color: #121212 !important;
}
.input-height {
    height: inherit;
}
.bigFieldBackgroundColor {
    margin-bottom: 0;
    display: flex;
    background-color: #2d2d2d !important;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #808080;
    opacity: 1; /* Firefox */
}
.form-control:disabled {
    background-color: #3a3a3a;
}
.infoBox {
    border-radius: 25px;
    position: absolute;
    width: 400px;
    background-color: #464646;
    z-index: 5;
    padding: 15px;
    border-color: #161616;
    border-style: solid;
    border-width: 5px;
}
.infoTitle {
    text-decoration: underline;
    display: flex;
    justify-content: center;
}
.infoText {
    font-size: 13px;
}
.custom-select {
    background-blend-mode: exclusion;
    background: #1a1a1a url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat
}
</style>

<style>
.fieldBackgroundColor {
    background-color: #1a1a1a !important;
}
.fieldBackgroundColor:disabled {
    background-color: #333333 !important;
}
</style>