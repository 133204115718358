import { render, staticRenderFns } from "./ReportFiles.vue?vue&type=template&id=48a80276&scoped=true"
import script from "./ReportFiles.vue?vue&type=script&lang=js"
export * from "./ReportFiles.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a80276",
  null
  
)

export default component.exports