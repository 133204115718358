<template>

    <div style="margin: 5px 10px 5px 10px" v-if="file !== undefined">

        <!--Main Body-->
        <div class="list-group-item p-0 clearfix">
            <div class="d-flex flex-row w-100">
                <div  class="d-flex align-items-center justify-content-center" style="border-radius: .2rem 0 0 .2rem">
                    <fa-icon :icon="getIcon()" size="2x"
                             class="p-3 align-self-center"
                             style="color: white;"
                             @click="showImagePreview"
                             :id="'docImage' + index"/>
                </div>
                <div style="white-space: normal">
                    <div class="h7 mb-0" style="color: #ffffff; margin-top: 3px; padding: 0;">
                        <div class="text-content" style="margin-right: 2px;">{{ file.name[0] }}</div>
                        <div v-if="file.metaData.description !== 'undefined'" class="text-muted text-uppercase font-weight-bold" style="font-size: xx-small">Description: {{file.metaData.description}}</div>
                        <div class="text-muted text-uppercase font-weight-bold" style="font-size: xx-small">Uploaded By: {{file.metaData.username}}</div>
                        <div class="text-muted text-uppercase font-weight-bold" style="font-size: xx-small">Upload Date: {{uploadDate}}</div>
                        <div class="text-muted text-uppercase font-weight-bold" style="font-size: xx-small; margin-bottom: 3px">File Size: {{fileSize}}</div>
                    </div>
                </div>
                <div class="ml-auto">
                    <div v-if="editable === true && file.metaData.userid === userId || isSupervisor === true" style="width: 32px;">
                        <fa-icon :icon="['fas', 'download']" @click="downloadFile()"
                           style="font-size: 0.8em; margin-left: 4px;"/>
                        <fa-icon :icon="['fas', 'close']" @click="deleteFile()" v-if="reportStatus !== 'Approved' && reportStatus !== 'Disposed'"
                           style="font-size: 1em; margin-left: 4px; transform: translateY(1px);"/>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 10px"/>
    </div>

</template>

<script>

export default {
    name: "ReportFile",
    props: {
        file: Object,
        editable: Boolean,
        title: String,
        index: Number,
        reportId: String,
        userName: String,
        userId: String,
        isSupervisor: Boolean,
        reportStatus: String,
    },
    data() {
        return {
            user: 'Admin',
            uploadDate: null,
            fileSize: ''
        }
    },
    created() {
        let date = new Date(this.file.lastModified);
        this.uploadDate = date.toLocaleString();

        if (this.file.size < 1000) {
            this.fileSize = this.file.size+'B';
        } else if (this.file.size < 1000000) {
            this.fileSize = Math.round(this.file.size/1000)+'KB';
        } else if (this.file.size < 1000000000) {
            this.fileSize = (this.file.size/1000000).toPrecision(2)+'MB';
        } else if (this.file.size < 1000000000000) {
            this.fileSize = (this.file.size/1000000000).toPrecision(2)+'GB';
        } else if (this.file.size < 1000000000000000) {
            this.fileSize = (this.file.size/1000000000000).toPrecision(2)+'TB';
        }
    },
    methods: {
        getIcon() {
            if (this.file.name[1] === 'pdf') {
                return ['far', 'file-pdf'];
            } else if (this.file.name[1] === 'exe') {
                return ['far', 'file-code'];
            } else if (this.file.name[1] === 'jpg' || this.file.name[1] === 'jfif' || this.file.name[1] === 'png' || this.file.name[1] === 'jpeg') {
                return ['far', 'file-image'];
            } else if (this.file.name[1] === 'mp4') {
                return ['far', 'file-video'];
            } else if (this.file.name[1] === 'zip') {
                return ['far', 'file-zipper'];
            } else {
                return ['far', 'file'];
            }
        },
        deleteFile() {
            this.$emit('deleteFile', this.file.name[0]);
        },
        downloadFile() {
            this.$emit('downloadFile', this.file.name[0]);
        },
        showImagePreview() {
            this.$emit('showImagePreview', {name: this.file.name[0], type: this.file.name[1]});
        },
    },

}
</script>

<style>
.text-content {
    min-width: 40%;
    word-break: break-word;
}
</style>