<template>
    <div :id="category._id" v-if="reportCategory !== undefined">
        <b-card no-body style="margin: 0; border: 0;" @click="openTab">
            <div slot="header">
                <span style="font-size: 16px">
                    {{this.category.name}}
                    <fa-icon :icon="['fas', 'triangle-exclamation']" v-if="!isSavable" style="color: #b4b428; font-size: 14px" title="Item Incomplete"/>
                </span>
                <div class="card-header-actions">
                    <fa-icon v-if="editable === true && category.singleItem === false"
                             :icon="['fas', 'circle-plus']"
                             style="color:white; padding-right: 8px; font-size: 21px; cursor: pointer;"
                             title="Add New Item"
                             @click="addItem"
                             @click.stop />
                    <b-link class="card-header-action btn-minimize">
                        <fa-icon v-if="toggle === true" :icon="['fas', 'angle-up']" style="color: white; font-size: 18px" title="Close Category"/>
                        <fa-icon v-else :icon="['fas', 'angle-down']" style="color: white; font-size: 18px" title="Open Category"/>
                    </b-link>
                </div>
            </div>
            <div @click.stop>
                <b-collapse v-model="toggle" visible style="padding:0 10px;" class="categoryBackgroundColor">
                    <Item v-if="category.singleItem === false" v-for="(item, index) in localCategory.items" :key="item._id"
                          :editable="editable"
                          :item="category.item"
                          :length="localCategory.items.length"
                          :index="index"
                          :google="google"
                          :categoryId="category._id"
                          :categoryName="category.name"
                          :reportItem="item"
                          :singleItem="category.singleItem"
                          @saveItem="saveItem"
                          @removeItem="removeItem"
                          @duplicateItem="duplicateItem"/>
                    <Field v-else :reportItem="localCategory.items[0]"
                                  :index="0"
                                  :item="category.item"
                                  :google="google"
                                  :categoryId="category._id"
                                  :categoryName="category.name"
                                  :editable="editable"
                                  :localItem="localCategory.items[0]"
                                  :singleItem="category.singleItem"
                                  :highlightFields="selectedField.values"
                                  @saveItem="saveItem"
                                  @selectField="selectField"
                                  style="background-color: #424246"/>
                </b-collapse>
            </div>
        </b-card>
    </div>
</template>

<script>
import PubSub from "pubsub-js";
import Item from "@/views/expansion_modules/reports/case_components/Item";
import Field from "@/views/expansion_modules/reports/case_components/Field";

export default {
    name: "Category",
    components: {
        Item,
        Field
    },
    props: {
        editable: Boolean,
        category: Object,
        reportCategory: Object,
        index: Number,
        google: HTMLScriptElement
    },
    data() {
        return {
            pubsubs: [],
            isSavable: false,
            savable: [],
            localCategory: {},
            selectedField: {values: [{id: null}]},
            toggle: false,
            pageLoad: false,
        }
    },
    created() {
        this.localCategory = Object.assign({}, this.reportCategory);
        this.savable.fill(true, 0, this.reportCategory ? this.reportCategory.items.length : 1);
        this.pubsubs.push(PubSub.subscribe('focusTab', (msg, data) => {
            if (data === this.category._id) {
                document.getElementById(this.category._id).scrollIntoView();
            }
        }));
        this.pubsubs.push(PubSub.subscribe('openTab', (msg, data) => {
            if (data.categoryId === this.category._id) {
                this.toggle = true;
                PubSub.publish('openChild', data);
            }
        }));
        this.pubsubs.push(PubSub.subscribe('openSingleItemTab', (msg, data) => {
            if (data.categoryId === this.category._id && data.open !== true) {
                this.toggle = !this.toggle;
            } else {
                this.toggle = true;
            }
            if (this.toggle === true) {
                PubSub.publish('focusField0', Object.assign(data, {index: 0}));
            }
        }));
        this.pubsubs.push(PubSub.subscribe('closeOpenAllOpenIncidentTabs', (msg, data) => {
            this.toggle = data.bool;
        }));
    },
    beforeDestroy() {
        this.pubsubs.forEach(pubsub => {
            PubSub.unsubscribe(pubsub)
        });
    },
    methods: {
        selectField(field) {
            this.selectedField = field;
        },
        removeItem(data) {
            this.$emit('removeItem', {categoryIndex: this.index, itemIndex: data.index, itemId: data.itemId, categoryId: this.category._id});
            this.savable.splice(data.index, 1)
            this.isSavable =  true;
            for (let e of this.savable) {
                if (!e) {
                    this.isSavable = false;
                    break;
                }
            }
            PubSub.publish('categoryIsSavable', {
                categoryId: this.category._id,
                isSavable: this.isSavable
            });
        },
        duplicateItem(data) {
            this.$emit('duplicateItem', {item: data.item, itemIndex: data.itemIndex, categoryIndex: this.index});
        },
        addItem() {
            this.$emit('addItem', {categoryId: this.category._id, index: this.index});
        },
        saveItem(obj) {
            if (this.savable[obj.index] !== obj.savable) {
                this.savable[obj.index] = obj.savable;
                this.isSavable = true;
                for (let e of this.savable) {
                    if (!e) {
                        this.isSavable = false;
                        break;
                    }
                }
            }
            PubSub.publish('categoryIsSavable', {
                categoryId: this.category._id,
                isSavable: this.isSavable
            });
            this.localCategory.items[obj.index] = obj.item;
            this.$emit('saveCategory', {category: this.localCategory, index: this.index, savable: this.isSavable});
        },
        openTab() {
            this.toggle = !this.toggle;
        },
    },
    watch: {
        toggle() {
            PubSub.publish('toggleSingleItemIcon', {itemId: this.localCategory.items[0]._id,  index: this.index});
        },
        object: {
            handler() {
                if (this.pageLoad === true) {
                    PubSub.publish('autoSaveRequest', {timer: 15000});
                } else {
                    //this will execute on page load unless we prevent it with this
                    this.pageLoad = true;
                }
            },
            deep: true
        }
    }
}
</script>

<style scoped>
div.card-header {
    background-color: #232323;
    padding: 12px 20px !important;
    transition: background-color 500ms;
}
div.card-header:hover {
    background-color: #141414;
}
.categoryBackgroundColor {
    background-color: #1c1c1c !important;
}

</style>