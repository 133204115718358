<template>
    <div v-if="notes !== undefined">

        <b-modal class="modal-dark" scrollable :title="'Add ' + title" v-model="addModalFlag" no-close-on-backdrop>
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>{{title}}</label>
                        <b-form-textarea v-model="newNote.text"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button @click="addModalFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="addNote()" size="sm" variant="primary"
                          :disabled="newNote.text === '' || disableButtonFlag">Submit
                </b-button>
            </template>
        </b-modal>

        <b-card style="width: 100%; height: 100%; margin-bottom: 10px; background-color: #1c1c1c; border: 0; border-radius: 0" no-body>
            <div slot="header">
                <div style="display: flex; align-content: space-between; align-items: center;">
                    <div class="d-flex align-items-center justify-content-center">
                        <strong>{{title}}s</strong>
                        <b-badge class="badge-success" v-if="editable === true && isSupervisor === true && reportStatus === 'Pending Approval'"
                                 style="height: fit-content; align-self: center; cursor: pointer; margin: 5px"
                                 @click="openModalAdd()">
                            <fa-icon :icon="['fas', 'plus']"/> Add
                        </b-badge>
                    </div>
                    <b-button v-if="isSupervisor === true && reportStatus === 'Pending Approval'" style="margin-left: auto" size="sm"
                              :disabled="notes.length === 0" @click="requestRevisions">Reject</b-button>
                </div>
            </div>
            <div v-if="notes.length > 0" class="d-flex flex-column" style="height: 100%; overflow-y: auto; padding: 4px">
                <div v-for="(note, index) in notes" :key="note._id">
                    <ReportRevision :note="note"
                             :title="title"
                             :index="index"
                             :userId="userId"
                             :isSupervisor="isSupervisor"
                             :isAdmin="isAdmin"
                             :editable="editable"
                             :editMethod="editMethod"
                             :changeRevisionStatus="changeRevisionStatus"
                             :deleteMethod="deleteMethod"/>
                </div>
            </div>
            <div v-else style="color: white; text-align: center; padding-top: 10px; font-size: 20px;">
                No Available Revisions
            </div>
        </b-card>
    </div>
</template>

<script>
import ReportRevision from './ReportRevision';

export default {
    name: "ReportRevisionsTab",
    components: {
        ReportRevision
    },
    props: {
        notes: Array,
        editable: Boolean,
        addMethod: Function,
        editMethod: Function,
        deleteMethod: Function,
        changeRevisionStatus: Function,
        title: String,
        height: Number,
        userName: String,
        userId: String,
        isSupervisor: Boolean,
        isAdmin: Boolean,
        reportStatus: String,
    },
    data() {
        return {
            sortedNotes: [],
            addModalFlag: false,
            disableButtonFlag: false,
            newNote: {
                text: '',
                completed: false,
                createdBy: this.userName,
                userId: this.userId
            },
        };
    },
    methods: {
        addNote() {
            this.disableButtonFlag = true;
            this.addMethod(this.newNote);
            this.addModalFlag = false;
        },
        openModalAdd() {
            this.disableButtonFlag = false;
            this.newNote = {
                text: '',
                completed: false,
                createdBy: this.userName,
                userId: this.userId
            };
            this.addModalFlag = true;
        },
        requestRevisions() {
            this.$emit('requestRevisions');
        }
    },
    watch: {
        notes() {
            if(this.notes !== undefined) {
                this.sortedNotes = [...this.notes];
            }
        }
    }
}
</script>

<style scoped>

</style>