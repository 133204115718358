<template>
    <div v-if="notes !== undefined">

        <b-modal class="modal-dark" scrollable :title="'Add ' + title" v-model="addModalFlag" no-close-on-backdrop>
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>{{title}}</label>
                        <b-form-textarea v-model="newNote.text"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Importance</label>
                        <b-form-select v-model="newNote.importance" :options="importanceOptions"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button @click="addModalFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="addNote()" size="sm" variant="primary"
                          :disabled="newNote.text === '' || disableButtonFlag">Submit
                </b-button>
            </template>
        </b-modal>

        <b-card style="width: 100%; height: 100%; margin-bottom: 10px; background-color: #1c1c1c; border: 0; border-radius: 0" no-body>
            <div slot="header">
                <div style="display: flex; align-content: space-between; align-items: center;">
                    <div class="d-flex align-items-center justify-content-center">
                        <strong>{{title}}s</strong>
                        <b-badge class="badge-success" v-if="editable === true && reportUserId === userId && reportStatus !== 'Approved' && reportStatus !== 'Pending Approval'"
                                 style="height: fit-content; align-self: center; cursor: pointer; margin: 5px"
                                 @click="openModalAdd()">
                            <fa-icon :icon="['fas', 'plus']"/> Add
                        </b-badge>
                    </div>
                    <b-form-select v-model="noteSort" size="sm" :options="noteSortOptions"
                                   style="width: fit-content; margin-left: auto;" @change="sortNotes"/>
                </div>
            </div>
            <div v-if="notes.length > 0" class="d-flex flex-column" style="height: 100%; overflow-y: auto; padding: 4px">
                <div v-for="(note, index) in sortedNotes" :key="note._id">
                    <Comment :note="note"
                             :title="title"
                             :index="index"
                             :userId="userId"
                             :isSupervisor="isSupervisor"
                             :editable="editable"
                             :reportStatus="reportStatus"
                             :editMethod="editMethod"
                             :deleteMethod="deleteMethod"/>
                </div>
            </div>
            <div v-else style="color: white; text-align: center; padding-top: 10px; font-size: 20px;">
                No Available Notes
            </div>
        </b-card>
    </div>
</template>

<script>
import Comment from './ReportComment';

export default {
    name: "ReportCommentsTab",
    components: {
        Comment
    },
    props: {
        notes: Array,
        editable: Boolean,
        addMethod: Function,
        editMethod: Function,
        deleteMethod: Function,
        title: String,
        height: Number,
        userName: String,
        userId: String,
        isSupervisor: Boolean,
        reportUserId: String,
        reportStatus: String,
    },
    data() {
        return {
            sortedNotes: [],
            addModalFlag: false,
            disableButtonFlag: false,
            newNote: {
                text: '',
                importance: 0,
                createdBy: this.userName,
                userId: this.userId
            },
            importanceOptions: [
                {value: 1, text: "High"},
                {value: 0, text: "Normal"},
                {value: -1, text: "Low"},
            ],
            noteSort: 0,
            noteSortOptions: [
                {text: "Most Important", value: 0},
                {text: "Least Important", value: 1},
                {text: "Newest", value: 2},
                {text: "Oldest", value: 3},
            ]
        };
    },
    created() {
        if (this.notes !== undefined) {
            this.sortedNotes = [...this.notes];
        }
        this.sortNotes();
    },
    methods: {
        addNote() {
            this.disableButtonFlag = true;
            this.addMethod(this.newNote);
            this.addModalFlag = false;
        },
        openModalAdd() {
            this.disableButtonFlag = false;
            this.newNote = {
                text: '',
                importance: 0,
                createdBy: this.userName,
                userId: this.userId
            };
            this.addModalFlag = true;
        },
        sortDates(cur, oth) {
            let nCur = cur.created_date;
            let nOth = oth.created_date;
            if (nCur < nOth) {
                return 1;
            } else if (nCur > nOth) {
                return -1;
            } else {
                return 0;
            }
        },
        sortImportance(cur, oth) {
            if (cur.importance < oth.importance) {
                return 1;
            } else if (cur.importance > oth.importance) {
                return -1;
            } else {
                return 0;
            }
        },
        sortNotes() {
            this.sortedNotes = this.sortedNotes.sort((cur, oth) => {
                let temp;
                switch (this.noteSort) {
                    case 0:
                        temp = this.sortImportance(cur, oth);
                        return temp !== 0 ? temp : this.sortDates(cur, oth);
                    case 1:
                        temp = this.sortImportance(oth, cur);
                        return temp !== 0 ? temp : this.sortDates(cur, oth);
                    case 2:
                        temp = this.sortDates(cur, oth);
                        return temp !== 0 ? temp : this.sortImportance(cur, oth);
                    default:
                        temp = this.sortDates(oth, cur);
                        return temp !== 0 ? temp : this.sortImportance(cur, oth);
                }
            });
        },
    },
    watch: {
        notes() {
            if(this.notes !== undefined) {
                this.sortedNotes = [...this.notes];
            }
            this.sortNotes();
        }
    }
}
</script>

<style scoped>

</style>