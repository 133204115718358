<template class="h-100">
    <div v-if="reportCategory !== undefined">
        <b-row>
            <b-col style="padding: 0 10px 0 10px">
                <div @click="focusTab()"
                     style="width: fit-content; font-size: 16px; padding: 6px 12px; cursor: pointer;"
                     class="rounded-0 fastNav">
                    {{ category.name }}
                    <fa-icon :icon="['fas', 'triangle-exclamation']" :id="'cautionCategory' + category._id"
                             style="color: #b4b428; font-size: 16px; display: none;" title="Item Incomplete"/>
                </div>

                <div v-for="(item, index) in reportCategory.items" :key="item._id" class="w-100">
                    <div @click="category.singleItem === false ? openTab(item._id) : openSingleItemTab()"
                         size="sm" class="rounded-0 fastNav"
                         style="width: fit-content; font-size: 13px; padding: 4px 8px 4px 25px; cursor: pointer;">
                        <fa-icon :icon="['fas', 'angle-right']" fixed-width :id="'sidebar' + item._id"/>
                        {{ category.item.name }} {{category.singleItem === false ? index + 1 : ''}}
                        <fa-icon :icon="['fas', 'triangle-exclamation']" :id="'cautionItem' + item._id"
                                 style="color: #b4b428; font-size: 14px; display: none" title="Require Field Empty"/>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>

export default {
    name: "NavBar",
    props: {
        category: Object,
        reportCategory: Object
    },
    methods: {
        focusTab() {
            this.$emit('focusTab', this.category._id);
        },
        openTab(id) {
            this.$emit('openTab', {categoryId: this.category._id, itemId: id});
        },
        openSingleItemTab() {
            this.$emit('openSingleItemTab', {categoryId: this.category._id});
        }
    }
}
</script>

<style scoped>
.fastNav {
    text-align: left;
    border: 0;
    background-color: #1c1c1c;
}
</style>