<template>

    <div style="margin: 4px" v-if="note !== undefined">

        <!--Remove Item Modal-->
        <b-modal class="modal-dark" scrollable :title="'Remove ' + title" v-model="deleteModalFlag">
            <p>If you would like to delete this {{deleteObjects.name}} please type the prompt below.</p>
            <br>
            <p style="margin-bottom: 0">Enter "{{deleteObjects.name}}":</p>
            <b-form-group description="case sensitive">
                <b-form-input id="basicName2" type="text" v-model="deleteObjects.deleteText"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button @click="deleteModalFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button :disabled="deleteObjects.deleteText !== deleteObjects.name || deleteButtonFlag" @click="deleteNote()"
                          size="sm"
                          variant="danger">Delete {{deleteObjects.name}}
                </b-button>
            </template>
        </b-modal>

        <!--Edit Note-->
        <b-modal class="modal-dark" scrollable :title="'Edit ' + title" v-model="editModalFlag" no-close-on-backdrop>
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>{{title}}</label>
                        <b-form-textarea v-model="editNoteTemp.text"></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col sm="12">
                    <b-form-group>
                        <label>Importance</label>
                        <b-form-select v-model="editNoteTemp.importance" :options="importanceOptions"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <template slot="modal-footer">
                <b-button @click="editModalFlag = false" size="sm" variant="secondary">Cancel</b-button>
                <b-button @click="editNote()" size="sm" variant="primary" :disabled="editNoteTemp.text === ''">Submit</b-button>
            </template>
        </b-modal>

        <!--Main Body-->
        <div class="list-group-item p-0 clearfix" id="comment">
            <div class="d-flex flex-row w-100 rounded">
                <div :style="'background-color:' + getColor()" class="d-flex flex-row" style="border-radius: .2rem 0 0 .2rem">
                    <i class="icon-speech p-2 font-2xl float-left align-self-center"></i>
                </div>
                <div style="margin-left: 10px;">
                    <div class="h7 mb-0" :style="'color: #ffffff; margin-top: 3px; padding: 0; width: ' + containerWidth + 'px;'">
                        <p class="text-content" style="padding-right: 5px; white-space: normal">{{ note.text }}</p>
                    </div>
                    <div class="text-muted text-uppercase font-weight-bold" style="font-size: xx-small">
                        Created By: {{ note.createdBy }}
                    </div>
                </div>
                <div class="ml-auto p-2; padding: 0" v-if="reportStatus !== 'Approved' && reportStatus !== 'Disposed'">
                    <div v-if="editable === true && note.userId === userId || isSupervisor === true" style="width: 32px;">
                        <fa-icon :icon="['fas', 'pen-to-square']" @click="openEdit()" style="font-size: 0.8em;"/>
                        <fa-icon :icon="['fas', 'close']" @click="openDelete()" style="font-size: 1em; margin-left: 4px; transform: translateY(1px)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "ReportComment",
    props: {
        note: Object,
        editable: Boolean,
        deleteMethod: Function,
        editMethod: Function,
        title: String,
        userId: String,
        isSupervisor: Boolean,
        reportStatus: String,
    },
    data() {
        return {
            containerWidth: null,
            deleteModalFlag: false,
            editModalFlag: false,
            deleteButtonFlag: false,
            editNoteTemp: {
                text: '',
                importance: 0
            },
            deleteObjects: {
                name: '',
                deleteText: '',
                id: null
            },
            importanceOptions: [
                {value: 1, text: "High"},
                {value: 0, text: "Normal"},
                {value: -1, text: "Low"},
            ]
        }
    },
    created() {
        this.deleteObjects.name = this.title;
        window.addEventListener("resize", this.resizeComments);
    },
    mounted() {
        let temp = document.getElementById('comment').offsetWidth;
        this.containerWidth = temp - 90;
    },
    destroyed() {
        window.removeEventListener("resize", this.resizeComments);
    },
    methods: {
        deleteNote() {
            this.deleteButtonFlag = true;
            this.deleteMethod(this.note);
            this.deleteModalFlag = false;
        },
        editNote() {
            this.editMethod(this.editNoteTemp);
            this.editModalFlag = false;
        },
        getColor() {
            switch (this.note.importance) {
                case 1:
                    return '#f64846';
                case -1:
                    return '#4dbd74';
                default:
                    return '#3283ba';
            }
        },
        openDelete() {
            this.deleteButtonFlag = false;
            this.deleteObjects.deleteText = '';
            this.deleteObjects.id = this.note.noteId;
            this.deleteModalFlag = true;
        },
        openEdit() {
            this.editNoteTemp = JSON.parse(JSON.stringify(this.note));
            this.editModalFlag = true;
        },
        resizeComments() {
            let temp = document.getElementById('comment').offsetWidth;
            this.containerWidth = temp - 77;
        }
    },
}
</script>

<style>
.text-content {
    min-width: 40%;
    word-break: break-word;
}
</style>